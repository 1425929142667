import { ElMessage } from 'element-plus'
import axios from 'axios'

var $ = document.querySelector.bind(document)
var vjsParsed, video, mediaSource
export default function parseTs(url, domID, resourceDataItem, loadingProp) {
  return new Promise(async function (resolve, reject) {
    axios({
      method: 'get',
      url: url,
      responseType: 'arraybuffer'
    }).then(async res => {
      try {
        await transferFormat(res.data, domID, resourceDataItem, loadingProp).catch(err => {
          resourceDataItem[loadingProp] = ''
          ElMessage.error('解析失败，请检查文件是否是原文件！')
        });
        resolve()
      } catch (err) {
        console.log(err);
        resourceDataItem[loadingProp + 'Loading'] = false
        ElMessage.error('解析失败，请检查文件是否是原文件！')
      }
    })
  })

}

/**
 *
 * @param {*} data
 * @param {*} domID  domID
 * @param {*} resourceDataItem 单个资源
 * @param {*} loadingProp  加载load的属性名
 */
function transferFormat(data, domID, resourceDataItem, loadingProp) {
  return new Promise((resolve, reject) => {
    // 将源数据从ArrayBuffer格式保存为可操作的Uint8Array格式
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/ArrayBuffer
    var segment = new Uint8Array(data);
    var combined = false;
    // 接收无音频ts文件，OutputType设置为'video'，带音频ts设置为'combined'
    var outputType = 'video';
    var remuxedSegments = [];
    var remuxedBytesLength = 0;
    var remuxedInitSegment = null;

    // remux选项默认为true，将源数据的音频视频混合为mp4，设为false则不混合
    var transmuxer = new muxjs.mp4.Transmuxer({ remux: false });

    // 监听data事件，开始转换流
    transmuxer.on('data', function (event) {
      if (event.type === outputType) {
        remuxedSegments.push(event);
        remuxedBytesLength += event.data.byteLength;
        remuxedInitSegment = event.initSegment;
      }
    });
    // 监听转换完成事件，拼接最后结果并传入MediaSource
    transmuxer.on('done', async function () {
      try {
        var offset = 0;
        var bytes = new Uint8Array(remuxedInitSegment.byteLength + remuxedBytesLength)
        bytes.set(remuxedInitSegment, offset);
        offset += remuxedInitSegment.byteLength;

        for (var j = 0, i = offset; j < remuxedSegments.length; j++) {
          bytes.set(remuxedSegments[j].data, i);
          i += remuxedSegments[j].byteLength;
        }
        remuxedSegments = [];
        remuxedBytesLength = 0;
        // 解析出转换后的mp4相关信息，与最终转换结果无关
        vjsParsed = muxjs.mp4.tools.inspect(bytes);
        await prepareSourceBuffer(combined, outputType, bytes, domID, resourceDataItem, loadingProp);
        resolve()
      } catch (error) {
        reject(error)
      }
    });
    // push方法可能会触发'data'事件，因此要在事件注册完成后调用
    transmuxer.push(segment); // 传入源二进制数据，分割为m2ts包
    // flush的调用会直接触发'done'事件，因此要事件注册完成后调用
    transmuxer.flush(); // 将所有数据从缓存区清出来
  })

}
function logevent(event) {
}
function prepareSourceBuffer(combined, outputType, bytes, domID, resourceDataItem, loadingProp) {
  return new Promise((resolve, reject) => {
    var buffer;
    video = document.createElement('video');
    video.controls = true;
    video.style.height = '100%'
    video.style.width = '100%'

    // MediaSource Web API: https://developer.mozilla.org/zh-CN/docs/Web/API/MediaSource
    mediaSource = new MediaSource();
    video.src = URL.createObjectURL(mediaSource);

    // 当前元素下面的子元素删除
    // var videoContainer = document.getElementById(domID);
    // videoContainer.innerHTML = '';

    $('#' + domID).appendChild(video);
    resourceDataItem[loadingProp + 'Loading'] = false

    // 转换后mp4的音频格式 视频格式
    var codecsArray = ["avc1.64001f", "mp4a.40.5"];

    mediaSource.addEventListener('sourceopen', function () {
      // 转换为带音频、视频的mp4
      if (combined) {
        buffer = mediaSource.addSourceBuffer('video/mp4;codecs="' + 'avc1.64001f,mp4a.40.5' + '"');
      } else if (outputType === 'video') {
        // 转换为只含视频的mp4
        buffer = mediaSource.addSourceBuffer('video/mp4;codecs="' + codecsArray[0] + '"');
      } else if (outputType === 'audio') {
        // 转换为只含音频的mp4
        buffer = mediaSource.addSourceBuffer('audio/mp4;codecs="' + (codecsArray[1] || codecsArray[0]) + '"');
      }

      buffer.addEventListener('updatestart', logevent);
      buffer.addEventListener('updateend', logevent);
      buffer.addEventListener('error', logevent);
      video.addEventListener('error', logevent);
      // mp4 buffer 准备完毕，传入转换后的数据
      // return callback(buffer);
      // 将 bytes 放入 MediaSource 创建的sourceBuffer中
      // https://developer.mozilla.org/en-US/docs/Web/API/SourceBuffer/appendBuffer
      buffer.appendBuffer(bytes);

      resolve()
      // 自动播放
      // video.play();
    });
  })

};
